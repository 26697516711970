
<div class="slider-blog">
    <VueSlickCarousel v-bind="settings">
        <div v-for="(publication, index) in publicationsData" :key="index" class="slide-content">
            <div class="col-12">
                <div class="blog-item">
                    <div class="img-thumb-blog"
                         v-if="publication.blog_imagen && publication.blog_imagen.public_path">
                        <img
                            loading="lazy"
                            width="248px"
                            height="167px"
                            :src="`${publication.blog_imagen.public_path}?size=mobile`"
                            :alt="publication.blog_imagen.alt"/>
                    </div>
                    <div class="blog-content-home">
                        <div class="sub-title mb-0 px-3">
                            {{ getCategoryTitle(publication) }}
                        </div>
                        <div class="blog-title" v-if="publication['title']">
                            <h4 class="reduced-text d-none d-sm-block" v-html="publication['title']"></h4>
                        </div>
                        <div class="blog-txt" v-if="publication['blurb']"
                             v-html="publication['blurb']"/>
                        <div class="blog-read-more">
                            <p @click="goToSection(publication)">
                                Leer más
                                <img v-img-prefix :src="`/public-assets/arrow-right-n`" alt="arrow-right-n"
                                     class="img-fluid" width="16" height="13"/>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </VueSlickCarousel>
    <div v-if="enableButton" class="col-12 button-bottom text-center mt-5">
        <a href="/blog" class="btn btn-primary btn-orange">
            <span class="px-5">Ir al blog</span>
        </a>
    </div>
</div>
